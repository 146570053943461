<template>
  <v-card elevation="0" outlined class="card-border">
    <v-card-title>
      <v-row dense>
        <v-col cols="auto" class="title">
          {{title + ' (' + this.ersItem.ersKey + ')'}}
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table disable-filtering disable-sort single-select dense
                  :items="items" :headers="headers"
                  :loading="loading" :items-per-page="10" item-key="no"
                  mobile-breakpoint="0" :footer-props="vs.footerPropsErsTable"
                  @click:row=""
      >
        <template v-slot:item.createdAt="{ item }">
          {{moment(item.createdAt).format('YYYY/MM/DD HH:mm')}}
        </template>
        <template v-slot:item.resend="{ item }">
          <v-btn class="ml-2" v-if="item.resend==1" small color="info" @click.stop="resendMessage(item.type, item.data._id)">재전송</v-btn>
          <v-btn class="ml-2" v-if="item.resend==2" small color="warning" @click.stop="resendMessage('at', item.data._id)">알림톡 재전송</v-btn>
        </template>

      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import vs from '@/utils/viewSupport'
import api from '@/services/api'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'ErsHistory',
  props: {
    title: { type: String, default: '' },
    ersItem: { type: Object, default: () => { return {} } },
  },
  data() {
    return {
      vs,
      moment,
      items: [],
      headers: [
        { text: 'I/F 시간', value: 'createdAt' },
        { text: 'I/F 번호', value: 'type' },
        { text: 'I/F 명칭', value: 'name' },
        { text: '조치코드', value: 'reqMessage' },
        { text: '진행상태명', value: 'progress' },
        { text: '전송 결과', value: 'status' },
        { text: 'I/F 재전송', value: 'resend' },
      ],
      loading: false,
      timer: null,
    }
  },
  methods: {
    list() {
      this.loading = true
      api.getSocarHistory(this.ersItem.ersKey).then(r => {
        this.items = r.result
      }).finally(() => { this.loading = false })
    },
    resendMessage(type, id) {
      this.loading = true

      console.log('resend: ' + type + ' - ' + id )

      api.resendSocarMessage(type, id).then(r => {

      }).finally(() => { 
        this.loading = false
        this.list()
      })
    },
  },
  mounted() {
    this.list()
  },
  watch: {
    ersItem() {
      this.list()
    }
  },
}
</script>

<style scoped>
.v-data-table >>> thead tr th {
  padding: 6px !important;
  font-weight: bold !important;
  background-color: black !important;
  color: white !important;
  font-size: 12px !important;
}

.v-data-table >>> tbody tr td {
  padding: 0px 0px 0px 0px !important;
  font-size: 11px !important;
}

>>> div.v-select__selections > div {
  font-size:14px !important;
}

.title {
  font-weight: bold;
}

.card-border {
  border: 2px solid black;
}
</style>
